<template>
  <page-header-wrapper :title="false">
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="标题">
                <a-input placeholder="请输入标题" v-model="queryParam.title" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="区域">
                <a-cascader
                  :options="regionList"
                  placeholder="省-市-区(县)"
                  :field-names="{label: 'label', value: 'value', children: 'children' }"
                  :value="addDefaultRegion"
                  @change="changeAddRegion"
                />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="小区">
                <a-select
                  placeholder="请选择小区"
                  v-model="queryParam.communityId"
                >
                  <a-select-option :key="0" :value="0">请选择小区</a-select-option>
                  <a-select-option v-for="item in regionCommunityAdd" :key="item.keyId" :value="item.id">
                    {{ item.communityName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item label="状态">
                  <a-select
                    v-model="queryParam.verifyStatus"
                    placeholder="状态"
                    allow-clear
                  >
                    <a-select-option :key="-1" :value="-1">请选择</a-select-option>
                    <a-select-option :key="0" :value="0">上架</a-select-option>
                    <a-select-option :key="1" :value="1">下架</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="发布时间">
                  <a-range-picker
                    format="YYYY-MM-DD"
                    valueFormat="YYYY-MM-DD"
                    v-model="searcDate"
                    @change="changeTime"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetSearchForm()">重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="editArticle(0)">添加</a-button>
      </div>
      <s-table
        ref="table"
        size="default"
        rowKey="keyId"
        :columns="columns"
        :data="loadData"
      >
        <span slot="communityName" slot-scope="text, record">
          {{ record.areaCodeName }} {{ record.communityName }}
        </span>
        <span slot="verifyStatus" slot-scope="text, record">
          <a-tag color="#87d068" v-if="record.verifyStatus === 0">已上架</a-tag>
          <a-tag color="#595959" v-else>已下架</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="gotoInfo(record.keyId)">查看</a>
          <a-divider type="vertical" />
          <a @click="editArticle(record.keyId)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确认要下架此活动吗？" @confirm="verifyArticle(record.keyId, 1)" v-if="record.verifyStatus === 0">
            <a>下架</a>
          </a-popconfirm>
          <a-popconfirm title="确认要上架此活动吗？" @confirm="verifyArticle(record.keyId, 0)" v-else>
            <a>上架</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a-popconfirm title="确认要删除此活动吗？" @confirm="delArticleApi(record.keyId)">
            <a>删除</a>
          </a-popconfirm>
        </span>
      </s-table>
      <article-info ref="articleinfo"></article-info>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import STree from '@/components/Tree/Tree'
import { STable, Ellipsis } from '@/components'
import { articleList, delArticle, verifyArticle } from '@/api/article'
import ArticleInfo from '@/views/article/components/ArticleInfo'
import { regionList } from '@/api/region'
import { regionCommunity } from '@/api/community'
const columns = [
  {
    title: '编号',
    dataIndex: 'keyId',
    width: '100px'
  },
  {
    title: '标题',
    dataIndex: 'title'
  },
  {
    title: '区域',
    dataIndex: 'communityName',
    scopedSlots: { customRender: 'communityName' }
  },
  {
    title: '状态',
    dataIndex: 'verifyStatus',
    width: '150px',
    scopedSlots: { customRender: 'verifyStatus' }
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    width: '200px'
  },
  {
    title: '操作',
    width: '220px',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'ArticleList',
  components: {
    STable,
    Ellipsis,
    STree,
    ArticleInfo
  },
  data () {
    this.columns = columns
    return {
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        title: '',
        type: 1,
        cateId: 0,
        verifyStatus: -1,
        communityId: 0,
        areaCode: '',
        startTime: '',
        endTime: ''
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        return articleList(requestParameters)
          .then(res => {
            return res.result
          })
      },
      searcDate: [],
      regionList: [],
      regionCommunity: [],
      regionCommunityAdd: [],
      addDefaultRegion: []
    }
  },
  filters: {
  },
  created () {
    this.regionListApi()
  },
  watch: {
    $route (val) {
      if (val.name === 'CommunityActivitiesList') {
        this.queryParam = {
          title: '',
          type: 1,
          cateId: 0,
          verifyStatus: -1,
          communityId: 0,
          areaCode: '',
          startTime: '',
          endTime: ''
        }
        this.searcDate = []
        this.addDefaultRegion = []
        this.$refs.table.refresh(true)
      }
    }
  },
  methods: {
    resetSearchForm () {
      this.queryParam = {
        title: '',
        type: 1,
        cateId: 0,
        verifyStatus: -1,
        communityId: 0,
        areaCode: '',
        startTime: '',
        endTime: ''
      }
      this.searcDate = []
      this.addDefaultRegion = []
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    delArticleApi (id) {
      console.log(id)
      const _this = this
      delArticle({ keyId: id }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.$message.success('操作成功')
          _this.$refs.table.refresh()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    editArticle (id) {
      console.log(id)
      this.$router.push({ path: '/article/add-article?articleId=' + id })
    },
    verifyArticle (id, status) {
      console.log(id, status)
      const _this = this
      verifyArticle({ keyId: id, verifyStatus: status }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.$message.success('操作成功')
          _this.$refs.table.refresh()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    changeTime (e) {
      this.queryParam.startTime = e[0] ? e[0] : ''
      this.queryParam.endTime = e[1] ? e[1] : ''
      this.searcDate = e
      console.log('changeTime', e)
    },
    gotoInfo (keyId) {
      this.$refs.articleinfo.noticeInfoApi(keyId)
    },
    regionListApi () {
      const _this = this
      regionList().then((res) => {
        if (res.errorCode === 0) {
          _this.regionList = res.result
          console.log('regionList', _this.regionList)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    changeAddRegion (e) {
      this.addDefaultRegion = e
      // this.queryParam.provinceId = e[0] ? e[0] : 0
      // this.queryParam.cityId = e[1] ? e[1] : 0
      this.queryParam.areaCode = e[2] ? e[2] : 0

      this.regionCommunityApi(this.queryParam.areaCode, 1)
      this.queryParam.communityId = 0
    },
    regionCommunityApi (areaCode, type) {
      const _this = this
      regionCommunity({ areaCode: areaCode }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          if (type === 0) {
            _this.regionCommunity = res.result.data
          } else {
            _this.regionCommunityAdd = res.result.data
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>
